import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import { Metadata } from '@mangoart/gatsby-ui/components/Metadata';
import { DefaultLayout } from '../layouts/Default';
import HistoryTextBlock from '../blocks/HistoryTextBlock';
import FluidTextBlock from '../blocks/FluidTextBlock';
import DefaultTextBlock from '../blocks/DefaultTextBlock';
import Services from '../blocks/Services';
import CallToAction from '../blocks/CallToAction';
import HeaderImage from '../blocks/HeaderImage';
import ScrollToTopButton from '../components/ScrollToTopButton';

import { ScrollToTopButton as ScrollToTopButtonClass, highlightedSection } from './zimmer.module.css';

interface DataProps {
  contactData: any;
  pageData: any;
  siteData: any;
  services: any;
}

const ZimmerPage: React.FC<PageProps<DataProps>> = ({ data, location }) => {
  const { contactData, pageData, siteData } = data;
  const {
    title: headerTitle,
    zimmerTitle,
    zimmerPriceAnnotation,
    zimmer,
    header,
    metadata,
    opengraph,
    geschichteText,
    ueberUnsText,
    serviceText,
    services,
    CTA,
    ausstattungText,
  } = pageData;
  const { title, canonicalSiteUrl } = siteData.siteMetadata;

  return (
    <DefaultLayout>
      <HeaderImage title={headerTitle} data={header} />
      <Services title={zimmerTitle} subtitle={zimmerPriceAnnotation} data={zimmer} id="zimmer" />
      <div className="withBorders">
        <DefaultTextBlock data={ausstattungText} />
        <CallToAction data={CTA} contactData={contactData} />
        <div
          className={highlightedSection}
          style={{
            padding: '8px 0',
          }}
        >
          <DefaultTextBlock data={serviceText} id="service" />
        </div>
        <Services data={services} layout="services" />
      </div>
      {ueberUnsText.map((paragraph, index) => (
        <FluidTextBlock data={paragraph} key={paragraph.text} id={index === 0 ? 'ueberuns' : ''} />
      ))}
      <div className={highlightedSection}>
        <HistoryTextBlock data={geschichteText} id="geschichte" />
      </div>
      <Metadata
        data={metadata}
        opengraphData={opengraph}
        defaultTitle={title}
        canonicalSiteUrl={canonicalSiteUrl}
        path={location.pathname}
      />
      <ScrollToTopButton className={ScrollToTopButtonClass} />
    </DefaultLayout>
  );
};

export default ZimmerPage;

export const ZimmerPageQuery = graphql`
  query ZimmerPageQuery {
    siteData: site {
      siteMetadata {
        title
        canonicalSiteUrl
      }
    }

    pageData: cockpitPageZimmer {
      title
      header {
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
        link1 {
          label
          target
        }
        link2 {
          label
          target
        }
        link3 {
          label
          target
        }
      }

      zimmerTitle
      zimmerPriceAnnotation
      zimmer {
        name
        icon
        price
        image {
          childImageSharp {
            gatsbyImageData(width: 533, height: 300)
          }
        }
      }

      geschichteText {
        title
        text
        align
        columns
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }

      serviceText {
        title
        text
        align
        columns
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }

      services {
        cockpitId
        title
        image {
          childImageSharp {
            gatsbyImageData(width: 533, height: 300)
          }
        }
      }

      ausstattungText {
        title
        text
        align
        columns
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }

      ueberUnsText {
        title
        text
        align
        columns
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }

      metadata {
        title
        metakeywords
        metadescription
      }
      CTA {
        title
        text
        linkLabel
      }

      opengraph {
        title
        description
        type
        image {
          childImageSharp {
            gatsbyImageData(width: 1200, height: 630)
          }
        }
      }
    }

    services: allCockpitRoomServices {
      edges {
        node {
          id
          title
          image {
            id
          }
        }
      }
    }

    contactData: cockpitRegionKontaktdaten {
      id
      postcode
      street
      city
      telephone
      fax
      email
      www
      gisCoordinates {
        lat
        lng
      }
      mapLink
      mapLinkZimmer
    }
  }
`;
